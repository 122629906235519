<template>
  <div style="float:left;width:100%">
    <router-view></router-view>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
import { Image as VanImage } from "vant";
import { Row, Col } from "vant";
import { Field } from "vant";
export default {
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [VanImage.name]: VanImage,
    [Row.name]: Row,
    [Col.name]: Col,
    [Field.name]: Field,
  },

  data() {
    return {
      active: 0,
    };
  },

  methods: {
    formatPrice() {
      return "¥" + (this.goods.price / 100).toFixed(2);
    },

    onClickCart() {
      this.$router.push("cart");
    },
  },
};
</script>
<style scoped>
.text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:0.9rem;
  color: #62bc7c;
}
</style>
